import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log("in twilio id controller");
  }

  getToken = () => {
    event.preventDefault();
    Rails.ajax({
      url: "/tokens",
      type: "POST",
      success: (data) => {
        console.log(data.token);
        this.getResource(data.token);
      },
    });
  };

  getResource = (token) => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("Identity", "candersson@simklarapp.com");
    const url = `https://chat.twilio.com/v2/Services/IS6053c00760cb4a639dc3c211a9513510/Users`;
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic U0thZDQ1NzNmZTc5ZDM5ZjMxMzJkMDYzOTQ3MTEzZGEwOTpreVczc1ZvTGFjNVdLa1VFNkhwVE5KR1BIeTlmUTRDdQ=="
    );
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: urlencoded,
    };
    const result = fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => console.log(json));
  };
}

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const config = {
      apiKey: "AIzaSyBgvz0IzFDZJfy-Q9Nh-xwzglEgmegcuII",
      authDomain: "smrtmfg.firebaseapp.com",
      databaseURL: "https://smrtmfg.firebaseio.com",
      projectId: "smrtmfg",
      storageBucket: "smrtmfg.appspot.com",
      messagingSenderId: "47917760042",
      appId: "1:47917760042:web:199f904f43830320cd8d53",
      measurementId: "G-LWXKFN4H6S",
    };
    console.log("firebase", firebase.apps);
    if (firebase.apps.length === 0) {
      firebase.initializeApp(config);
    }
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(function () {
        console.log("Have permission");
        return messaging.getToken();
      })
      .then(function (token) {
        saveToken(token);
      })
      .catch(function (err) {
        console.log("Error occured", err);
      });
    messaging.onMessage(function (payload) {
      console.log("onMessage: ", payload);
    });
  }
}
const saveToken = (token) => {
  const url = "/user_tokens";
  let data = { token: token };

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  });
};
